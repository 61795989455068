import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { FormattedMessage } from "gatsby-plugin-intl";

import { Title, Button, Section, Box, Text, Input } from "../components/Core";

import PageWrapper from "../components/PageWrapper";
import { device } from "../utils";

const FormStyled = styled.form``;

const WidgetWrapper = styled(Box)`
  border-radius: 10px;
  background-color: #f7f7fb;
  padding-top: 80px;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-right: 50px;
  @media ${device.lg} {
    padding-left: 140px;
    padding-right: 150px;
  }
  @media ${device.xl} {
    padding-left: 150px;
    padding-right: 150px;
  }
`;

const Contact = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Section>
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                <div className="banner-content">
                  <Title variant="hero">
                    <FormattedMessage id="WereHereToHelp" />
                  </Title>
                  <Text>
                    <FormattedMessage id="WereHereToHelpNotes" />
                  </Text>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center mt-5 pt-lg-5">
              <Col xl="10">
                <WidgetWrapper>
                  <Row>
                    <Col md="2" sm="6"></Col>
                    <Col md="4" sm="6">
                      <Box className="mb-5">
                        <Title variant="card" fontSize="24px">
                          <FormattedMessage id="CallUs" />
                        </Title>
                        <Text>+1-492-4918-395</Text>
                        <Text>+14-394-409-591</Text>
                      </Box>
                    </Col>
                    <Col md="4" sm="6">
                      <Box className="mb-5">
                        <Title variant="card" fontSize="24px">
                          <FormattedMessage id="EmailUs" />
                        </Title>
                        <Text>info@mail.com</Text>
                        <Text>support@mail.com</Text>
                      </Box>
                    </Col>
                    {/* <Col md="4" sm="6">
                      <Box className="mb-5">
                        <Title variant="card" fontSize="24px">
                          Contact us
                        </Title>
                        <Text>34 Madison Street,</Text>
                        <Text>NY, USA 10005</Text>
                      </Box>
                    </Col> */}
                  </Row>
                </WidgetWrapper>
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default Contact;
